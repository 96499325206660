.Reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.left-side{
    flex: 1 1;
    display: grid;
    grid-template-columns:repeat(3, 1fr) ;  /*   for subgrid: divide grid in 3 parts with 1fr(width) each*/
    gap: 1rem;
   grid-auto-rows: 1fr;
   
   
}

.left-side>img{
    object-fit: cover;
}
.left-side>:nth-child(1){
    width: 16rem;
    grid-row: 1/3; /* takes all the row of grid */
    height: 35rem;
}
.left-side>:nth-child(2){
    width: auto;
    height: 20rem;
    grid-column: 2/4;/*  from above grid which has 3 subgrid take column from 2 to 4 i.e 2 sub grid */
}
.left-side>:nth-child(3){
    height: 14rem;
    grid-row: 2;
    grid-column: 2/3;
}
.left-side>:nth-child(4){
    width: 12.8rem;
    grid-row: 2;
    grid-column: 3/4;
}



.right-side{
    flex: 1 1;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-side>span{
    font-weight: bold;
    color: var(--orange);
    font-size: 2rem;
}
.right-side>:nth-child(2){
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details>div{
    display: flex;
    font-size: 1.3rem;
    gap: 1rem;
}
.details>div>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1rem;  
}
.partners>img{
    width: 3rem;
}

/* responsive */
@media screen and (max-width:768px){
    .Reasons{
        flex-direction: column;
    }
    .left-side{
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .left-side>:nth-child(1){
        width: 7rem;
        height: 20rem;
    }
    .left-side>:nth-child(2){
        width: 12rem;
        height: 10rem;
    }
    .left-side>:nth-child(3){
        width: 7rem;
        height: 9rem;
    }
    .left-side>:nth-child(4){
        width: 7rem;
        height: 9rem;
    }
    
}