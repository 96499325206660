.hero{
    display: flex;
    justify-content:space-between ;
   
}

.h-blur{
    width: 22rem;
    height: 35rem;
    left: 10rem;
    padding-top: 10rem;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 2;     /* left side has 3 time width of right-h  
                   flex is flexible length of class   */
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.right-h{
    flex: 1;
    position: relative ; /* relative to left side */
    background-color: var(--orange);
}

.ad{
    margin-top: 4rem;
    background-color: #363636;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ad>span{
    z-index: 2; /*   w.r.t ad>div it is for which to be on top of other */
}

.ad>div{
    position: absolute;
    background-color: var(--orange);
    width: 5rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 4.5rem;
    color: white;
    text-overflow: inherit;
}

.hero-text>div:nth-child(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;

}

.stats{
   display: flex;
   gap: 2rem;

}

.x{
    display: flex;
}

.stats>div{
    display: flex;
    flex-direction: column;
}

.stats>div>span:nth-child(1){
    color: white;
    font-size: 2rem;
}

.stats>div>span:nth-child(2){
    color: var(--gray);
    text-transform: none;
}

.btn{
    background-color: white;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px transparent;
    transition: 300ms all;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn:hover{
    cursor: pointer;
    background-color: rgb(9, 11, 11);
}

.buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;

}

.buttons>:nth-child(1){
     color: white;
     background-color: var(--orange);
     width: 8rem;
}
.buttons>:nth-child(1):hover{
    color: rgb(18, 16, 16);
    background-color: rgb(88, 87, 87);
    width: 8rem;
}

.buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}
.buttons>:nth-child(2):hover{
    color: rgb(18, 16, 16);
    background-color: var(--orange);
    width: 8rem;
}

.right-h>.btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>:nth-child(2){
    color: var(--gray);
}
.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}

.hero-image{
    position: absolute;
    top: 10rem;
    right: 18rem;
    width: 23rem;
}

.hero-image-back{
    position: absolute;
    top: 4rem;
    right: 30rem;
    z-index: -1;
    width: 15rem;
}

.calorie{
   display: flex;
   flex-direction: row;
   gap: 2rem;
   background-color: var(--caloryCard);
   border-radius: 5px;
   padding: 1rem;
   width: 15rem;
   position: absolute;
   top:33rem;
   right: 37rem;
}
.calorie>img{
    width: 3rem;
}

.calorie>:nth-child(2){
    color: white;
    font-size: 1.5rem;
    padding-top: .6rem;
}
.calorie>:nth-child(3) {
    color: var(--gray);
    padding-top: 1.7rem;
    padding-right: 2rem;
}

/* responsive */
@media screen and (max-width:768px) {
    .hero{
        flex-direction: column;
    }
    .h-blur{
        width: 14rem;
    }
    .ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: 3rem;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-child(3){
        font-size: large;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .buttons{
        justify-content: center;
    }
    .stats>div>span:nth-child(1){
        font-size: large;
    }

/* right side */
   .right-h{
    position: relative;
    background: none;
   }
   .heart-rate{
    left: 1rem;
    top: 2rem;
   }
   .calorie{
    position: relative;
    top: 5rem;
    left: 2rem;
    width: 14rem;
   }
   .calorie>:nth-child(2){
    padding-top: 1.4rem;
    font-size: 1.2rem;
   }
   .hero-image{
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;
   }
   .hero-image-back{
    width: 15rem;
    left: 2rem;
    top: 0rem;
   }

}