.join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.join-left{
    color: white;
    font-size: 4rem;
    font-weight: bold;
    position: relative;
}
.join-left>hr{
    position: absloute;
    width: 15rem;
    border: 2px solid var(--orange) ;
    border-radius: 20%;
    margin: 12px 0;
}

.join-right{
    display: flex;
   justify-content: center;
   align-items: flex-end;
   padding-bottom: 3rem;

   
}

.email-div{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
}
.email-div>input{
    border: 0.1rem solid black;
    border-radius: 0.2rem;
}

.btn{
    background-color: orange;
}
.btn:hover{
    background-color: rgb(236, 132, 4);
}

/* responsive */
@media screen and (max-width:768px){
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .join-left{
        font-size: 250%;
    }
    .join-right{
        align-self: center;
        width: fit-content;
        padding-right: 1%;
    }
    
}