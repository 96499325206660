.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 10rem ;
    height: 4rem;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}



.header-menu>li:hover{
     color: var(--orange);
     cursor: pointer;
}

/* responsive */
@media screen and (max-width:768px){
    .header>.header-menu{
        position: fixed;
        right: 2rem;
        z-index: 99;
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}